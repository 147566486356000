import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Answer,  Line, Page, Question, Section } from "../objects";
import ControlView from "./ControlView";
import ControlEdit from "./ControlEdit";

interface PageEditControlProps extends WithTranslation {

    allowEdit: boolean;

    page: Page;
    index: number;
    moveid: string;
    onChange: (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, answer: Answer) => void;

    onDrop: (sindex: number, lindex: number, position: number, event: any) => void; //hide it

    onClose: (id: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, value: Question) => void; //hide it
    onDelete: (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number) => void; //delete it

    onAddSection: (pageIndex: number) => void;

    onDragStart: (event: any) => void;
    onDragEnd: (event: any) => void;

}
interface PageEditControlState {
    id: string;
    pageIndex: number;
    sectionIndex: number;
    lineIndex: number;
    controlIndex: number;
}

class PageEditControl extends React.Component<PageEditControlProps, PageEditControlState> {
    /*
    constructor(props: PageEditControlProps) {
        super(props);
    }
*/

    public state: PageEditControlState = {
        id: "",
        pageIndex: 0,
        sectionIndex: 0,
        lineIndex: 0,
        controlIndex: 0
    };

    public componentDidMount() {



    }


    private onEdit = (id: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number) => {
        console.log("PageEditControl onEdit");
        this.setState({
            id: id,
            pageIndex: pageIndex,
            sectionIndex: sectionIndex,
            lineIndex: lineIndex,
            controlIndex: controlIndex
        });
    }




    private onClose = (id: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, q: Question) => {

        console.log("PageEditControl onClose");
        //update
        this.props.onClose(id, pageIndex, sectionIndex, lineIndex, controlIndex, q);

        this.setState({
            id: "",
            pageIndex: -1,
            sectionIndex: -1,
            lineIndex: -1,
            controlIndex: -1
        });
    }

    private addBorder = (id: string) => {
        console.log("addBorder", id);
        var elem = document.getElementById(id);
        if (elem != null) {
            var c1 = 'dashed'; elem.classList.add(c1);
        }
    }

    private removeBorder = (id: string) => {
        console.log("removeBorder", id);
        var elem = document.getElementById(id);
        if (elem != null) {
            var c1 = 'dashed'; elem.classList.remove(c1);
        }
    }



    private onDragEnterLine = (id: string) => {

        console.log("onDragEnterLine", id);

        //accept only properties

        //console.log("target", event.target.id);
        /*
        if (this.props.moveid.indexOf("s1") == 0 && event.target.id.indexOf("d1") == 0) {
            this.addBorder(event.target.id);
        }
        if (this.props.moveid.indexOf("s2") == 0 && event.target.id.indexOf("d2") == 0) {
            this.addBorder(event.target.id);
        }
        */

        if (this.props.allowEdit) {
            const allElements = document.querySelectorAll('.dashed');
            allElements.forEach((element) => {
                element.classList.remove('dashed');
            });

            this.addBorder(id);
        }
    }

    private onDragEnter = (event: any) => {

        console.log("onDragEnter", event.target.id);

        //accept only properties

        //console.log("target", event.target.id);
        /*
        if (this.props.moveid.indexOf("s1") == 0 && event.target.id.indexOf("d1") == 0) {
            this.addBorder(event.target.id);
        }
        if (this.props.moveid.indexOf("s2") == 0 && event.target.id.indexOf("d2") == 0) {
            this.addBorder(event.target.id);
        }
        */
        if (this.props.allowEdit) {
            this.addBorder(event.target.id);
        }
    }


    private onDragOver = (event: any) => {
        //allow drop

        //console.log("onDragOver", event);

        event.preventDefault();
    }



    private onDragLeave = (event: any) => {
        event.preventDefault();
        this.removeBorder(event.target.id);
    }

    private onDrop = (sindex: number, lindex: number, position: number, event: any) => {
        event.preventDefault();



        console.log("drop", event, event.target.id, this.props.moveid);

        this.props.onDrop(sindex, lindex, position, event);

    }


    public render() {

        var newQuestion: Question = {
            type: 0,
            label: "",
            description: "",
            placeHolder: "",
            required: false,
            min: "",
            max: "",
            step: "",
            pattern: "",
            name: "",
            identifier: "",
            answer: { identifier: "", name: "", response: "", options: [] },
            options: []
        }

        return (
            <>


                {this.props.page != null &&
                    <div key={"page" + this.props.index} id="page" className={"m-2 p-2 rounded-sm shadow-sm " + (this.props.allowEdit ? " hover:border-[1px] hover:border-[#FF000020] " : "")}>

                        <div key={"pagel_" + this.props.index} className="">
                            <ControlView
                                key={"pagel11_" + this.props.index}
                                identifier={"pageview"}
                                onEdit={this.onEdit}
                                isControl={false}
                                pageIndex={this.props.index}
                                sectionIndex={-1}
                                lineIndex={-1}
                                questionIndex={-1}
                                q={{ ...newQuestion, type: 0, identifier: "pageview", label: this.props.page.label, description: this.props.page.description }}
                                onDelete={this.props.onDelete}
                                allowEdit={this.props.allowEdit}
                                onChange={() => { }} />
                        </div>

                        {this.props.allowEdit && this.state.id == "pageview" && this.state.pageIndex == this.props.index && this.state.sectionIndex == -1 && this.state.lineIndex == -1 && this.state.controlIndex == -1 &&
                            <ControlEdit key={"pagel12_" + this.props.index}
                                identifier="page"
                                onClose={this.onClose}
                                pageIndex={this.props.index}
                                sectionIndex={-1}
                                lineIndex={-1}
                                controlIndex={-1}
                                arrow={50}
                                isControl={false}
                                q={{ ...newQuestion, type: 0, identifier: "pageview", label: this.props.page.label, description: this.props.page.description }}
                            />
                        }

                        {this.props.page.sections != null && this.props.page.sections.length > 0 &&

                            this.props.page.sections.map((section: Section, sindex: number) =>
                                <div key={"section" + this.props.index + "_" + sindex} className={"m-2 p-2 rounded-md " + (this.props.allowEdit ? " hover:border-[1px] hover:border-[#FF000020] " : "")}>

                                    <div key={"sectionl_" + this.props.index + "" + sindex} className="text-center" draggable={true} onDragStart={this.props.onDragStart}>
                                        <ControlView
                                            key={"sectionl1" + this.props.index + "_" + sindex}
                                            identifier="sectionview"
                                            onEdit={this.onEdit}
                                            isControl={false}
                                            pageIndex={this.props.index}
                                            sectionIndex={sindex}
                                            lineIndex={-1}
                                            questionIndex={-1}
                                            q={{ ...newQuestion, type: 0, identifier: "sectionview", label: section.label, description: section.description }}
                                            onDelete={this.props.onDelete}
                                            allowEdit={this.props.allowEdit}
                                            onChange={() => { }}
                                        />
                                    </div>



                                    {this.props.allowEdit && this.state.id == "sectionview" && this.state.pageIndex == this.props.index && this.state.sectionIndex == sindex && this.state.lineIndex == -1 && this.state.controlIndex == -1 &&
                                        <ControlEdit key={"sectionl12_" + this.props.index + "_" + sindex} identifier="section" onClose={this.onClose} pageIndex={this.props.index} sectionIndex={sindex} lineIndex={-1} controlIndex={-1} arrow={50} isControl={false}
                                            q={{ ...newQuestion, type: 0, identifier: "sectionview", label: section.label, description: section.description }}
                                        />
                                    }

                                    {section.lines != null && section.lines.length > 0 &&
                                        section.lines.map((line: Line, lindex: number) =>

                                            <div key={"div" + lindex} className="">
                                                <div key={"line_" + this.props.index + "_" + sindex + "_" + lindex}
                                                    id={"line" + sindex + "_" + lindex}
                                                    className="flex flex-wrap w-full hover:border-[1px] hover:border-dotted hover:border-[#FF000020]"

                                                    onDragEnter={() => this.onDragEnterLine("line" + sindex + "_" + lindex)}
                                                >

                                                    {/*dinfo("line")*/}

                                                    <div key={"question_left" + this.props.index + "_" + sindex + "_" + lindex}
                                                        className={"newdrop flex-1 p-2 rounded-md delete1 " + (this.props.allowEdit ? " hover:border-[1px] hover:border-[#FF000020] " : "")}
                                                        onDrop={(e) => this.onDrop(sindex, lindex, 0, e)} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}
                                                    >

                                                    </div>

                                                    {line.questions != null && line.questions.length > 0 &&

                                                        line.questions.map((question: Question, qindex: number) =>
                                                            <React.Fragment key={"question_fragment" + this.props.index + "_" + sindex + "_" + lindex + "_" + qindex}>
                                                                <div key={"question_" + this.props.index + "_" + sindex + "_" + lindex + "_" + qindex}
                                                                    id={"question_" + this.props.index + "_" + sindex + "_" + lindex + "_" + qindex}
                                                                    className={"flex-1 items-center p-2 rounded-md delete1 " + (this.props.allowEdit ? " hover:border-[1px] hover:border-[#FF000020] " : "")}
                                                                    draggable={this.props.allowEdit} onDragStart={this.props.onDragStart} onDragEnd={this.props.onDragEnd}
                                                                >
                                                                    <ControlView
                                                                        key={"questionv_" + this.props.index + "_" + sindex + "_" + lindex + "_" + qindex}
                                                                        identifier="questionview"
                                                                        onEdit={this.onEdit}
                                                                        isControl={true}
                                                                        pageIndex={this.props.index}
                                                                        sectionIndex={sindex}
                                                                        lineIndex={lindex}
                                                                        questionIndex={qindex}
                                                                        q={question}
                                                                        onDelete={this.props.onDelete}
                                                                        allowEdit={this.props.allowEdit}
                                                                        onChange={this.props.onChange}
                                                                    />
                                                                </div>
                                                                <div key={"question_right" + this.props.index + "_" + sindex + "_" + lindex + "_" + qindex}
                                                                    className={"newdrop flex-1 p-2 rounded-md delete1 " + (this.props.allowEdit ? " hover:border-[1px] hover:border-[#FF000020] " : "")}
                                                                    onDrop={(e) => this.onDrop(sindex, lindex, qindex + 1, e)} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}
                                                                >

                                                                </div>
                                                            </React.Fragment>
                                                        )}


                                                </div>
                                                {this.props.allowEdit &&
                                                    <div key={"questionedit_" + this.props.index + "_" + sindex + "_" + lindex + "_new"} >
                                                        {this.state.id == "questionview" && this.state.pageIndex == this.props.index && this.state.sectionIndex == sindex && this.state.lineIndex == lindex && this.state.controlIndex > -1 &&
                                                            <ControlEdit key={"questionedit_" + this.props.index + "_" + sindex + "_" + lindex + "_" + "edit"} identifier="questionview" onClose={this.onClose} pageIndex={this.props.index} sectionIndex={sindex} lineIndex={lindex} controlIndex={this.state.controlIndex}
                                                                q={line.questions[this.state.controlIndex]} arrow={this.state.controlIndex * 100 / line.questions.length} isControl={true}
                                                            />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )}

                                    {this.props.allowEdit &&
                                        <div key={"divlinenew"} className="mt-5 p-2">
                                            <div key={"linenew3" + this.props.index + "_" + sindex}
                                                id={"linenew3" + this.props.index + "_" + sindex}
                                                className="w-full hover:border-[1px] hover:border-dotted hover:border-[#FF000020]"
                                                onDragEnter={() => this.onDragEnterLine("linenew3" + this.props.index + "_" + sindex)}
                                            >

                                                <div key={"question_left" + this.props.index + "_" + sindex}
                                                    className={"newdrop flex-1 p-2 border-[1px] border-dotted border-[#CCCCCC] rounded-md h-[2.5rem] leading-5 align-top text-[#CCCCCC]"} style={{ display: 'block' }}
                                                    onDrop={(e) => this.onDrop(sindex, section.lines.length, 0, e)} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}
                                                >
                                                    new line
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            )}
                        {this.props.allowEdit &&
                            <div className="text-right">
                                <button key={"pagenew"} id={"pagenew"}
                                    onClick={() => { this.props.onAddSection(this.state.pageIndex) }}
                                    className=" inline bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block m-1 p-2 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]" >Add Section</button>
                            </div>
                        }
                    </div>


                }
                {/*<div className="text-center">{this.props.index + 1}</div>*/}
                {/*dinfo(this.state)*/}

                {/*dinfo(this.state)*/}
            </>
        );

    }
}

export default withTranslation()(PageEditControl)

/// ms sql fast way do delete some records from big table