import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {  Line, Page, Question, Section, w } from "../objects";


interface PagePreviewControlProps extends WithTranslation {
    page: Page;
    index: number;
}
interface PagePreviewControlState {

}
/*
const defaultForm: Form = {
    id: 0,
    identifier: "",
    isVersion: false,
    label: "",
    modifiedDate: "",
    startDate: "",
    endDate: "",
    setup: {
        label: "",
        description: "",
        pages: [],
    }
}
*/

const InputPreview = (length: number) => {

    if (length==0) return (<></>);
    if (length<20) length = 10;
    if (length>100) length = 100;

    return (<div className='block bg-[#CCCCCC] mb-[1px] h-[6px]' style={{ width: length + '%' }}>&nbsp;</div>);

}

class PagePreviewControl extends React.Component<PagePreviewControlProps, PagePreviewControlState> {
    /*
    constructor(props: PagePreviewControlProps) {
        super(props);
    }
*/

    public state: PagePreviewControlState = {

    };

    public componentDidMount() {



    }




    public render() {


        return (
            <>
                <div key={"page" + this.props.index} className="m-1 p-1 rounded-md text-[#CCCCCC]">

                    <div key={"pagel" + this.props.index} className="font-bold  ">{InputPreview(this.props.page.label.length)}</div>
                    <div key={"paged" + this.props.index} className="">{InputPreview(this.props.page.description.length)}</div>
                    {this.props.page.sections != null && this.props.page.sections.length > 0 &&

                        this.props.page.sections.map((section: Section, index: number) =>
                            <div key={"section" + index} className="m-1 p-1 ">
                                <div key={"sectionl" + index} className="font-bold">{InputPreview(50)}</div>
                                <div key={"sectiond" + index}>{InputPreview(80)}</div>

                                {section.lines != null && section.lines.length > 0 &&
                                    section.lines.map((line: Line, index: number) =>
                                        <div key={"line"+index} className="w-full flex">
                                            {line.questions != null && line.questions.length > 0 &&

                                                line.questions.map((question: Question, index: number) =>
                                                    <div key={"question" + index} className={"m-1 p-1 " + w(line.questions.length)}>
                                                        <div key={"questionq" + index} className="font-bold">{InputPreview(question.label.length)}</div>
                                                        {/*<div key={"questiond" + index}>{InputPreview(50)}</div>*/}

                                                        {InputPreview(100)}

                                                    </div>
                                                )}

                                        </div>
                                    )}

                            </div>
                        )}

                </div>
                {/*<div className="text-center">{this.props.index + 1}</div>*/}

                <span className=""> </span>
<div className="w-[100%] w-[50%] w-[33%] w-[25%] w-[16%] "></div>
                {/*dinfo(this.props)*/}
            </>
        );

    }
}

export default withTranslation()(PagePreviewControl)

//inline edit component