import * as React from 'react';
import { dinfo, Question, Option } from '../objects';
import Icons from './Icons';


interface ControlEditProps {
    identifier: string;
    pageIndex: number;
    sectionIndex: number;
    lineIndex: number;
    controlIndex: number;

    q: Question;
    //title: string;
    //value: string;

    isControl: boolean;
    arrow: number;

    onClose: (identifier: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, value: Question) => void; //hide it
}

interface ControlEditState {
    q: Question;
}



export default class ControlEdit extends React.PureComponent<ControlEditProps, ControlEditState> {
/*
    constructor(props: ControlEditProps) {
        super(props);
  }
  */

    public state: ControlEditState = {
        q: {
            ...this.props.q, options: (this.props.q.options != null && this.props.q.options.length > 0 ? this.props.q.options : [{ text: this.props.q.label, value: this.props.q.name }])
        }
    };

    /*
    public componentDidMount() {

    }
    */
    public componentDidUpdate() {
        /*this.setState({
            q: this.props.q
        });
        */
    }

    public renderLabel() {

        return (
            <>
                <div className='flex p-2'>
                    <div className='w-1/4'>Title</div>
                    <div className='w-3/4'>
                        <input type="text" id="label" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.label} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>
                <div className='flex p-2'>
                    <div className='w-1/4'>Description</div>
                    <div className='w-3/4'>
                        <input type="text" id="description" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.description} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>

            </>
        );
    }

    public renderText() {

        return (
            <>
                <div className='flex p-2'>
                    <div className='w-1/4'>Title</div>
                    <div className='w-3/4'>
                        <input type="text" id="label" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.label} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>
                <div className='flex p-2'>
                    <div className='w-1/4'>Description</div>
                    <div className='w-3/4'>
                        <input type="text" id="description" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.description} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>

            </>
        );
    }

    public renderTextArea() {

        return (
            <>
                <div className='flex p-2'>
                    <div className='w-1/4'>Title</div>
                    <div className='w-3/4'>
                        <input type="text" id="label" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.label} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>
                <div className='flex p-2'>
                    <div className='w-1/4'>Description</div>
                    <div className='w-3/4'>
                        <input type="text" id="description" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.description} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>

            </>
        );
    }



    public renderSelect() {

        return (
            <>
                <div className='flex p-2'>
                    <div className='w-1/4'>Title</div>
                    <div className='w-3/4'>
                        <input type="text" id="label" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.label} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>
                <div className='flex p-2'>
                    <div className='w-1/4'>Description</div>
                    <div className='w-3/4'>
                        <input type="text" id="description" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.description} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                </div>


            </>
        );
    }


    public renderOptions() {
        return (
            <div className='flex p-2'>
                <div className='w-1/4'>Options</div>
                <div className='w-3/4 p-2 '>

                    <div key={"ol"} className="flex border-[1px]  border-[#CCCCCC] text-center">
                        <div key={"o1"} className="w-1/2 p-1">
                            Value
                        </div>
                        <div key={"o2"} className="w-1/2 p-1">
                            Text
                        </div>
                    </div>
                    {this.state.q.options != null && this.state.q.options.length > 0 &&
                        this.state.q.options.map((x: Option, index: number) =>
                            <div key={"o" + index} className="flex border-x-[1px] border-b-[1px] border-[#CCCCCC]">
                                <div key={"o1" + index} className="w-1/2 p-1">
                                    <input type="text" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={x.value || ""} onChange={(e) => { this.onOptionChange(index, "value", e) }} />

                                </div>
                                <div key={"o2" + index} className="w-1/2 p-1">
                                    {index != 0 &&
                                        <span className='float-right' onClick={() => { this.onOptionDelete(index) }}><Icons title='Delete' type='delete' /></span>
                                    }
                                    <input type="text" className="w-[90%] rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2" value={x.text || ""} onChange={(e) => { this.onOptionChange(index, "text", e) }} />

                                </div>
                            </div>
                        )
                    }

                    <div key={"oa"} className="">

                        <div key={"o2"} className="w-full p-2">
                            <span className=' float-right' onClick={() => { this.onOptionAdd() }}><Icons title='Add' type='add' /></span>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    public render() {



        return (
            <div className=' '>
                {dinfo(this.props.q.type)}
                {dinfo("props")} {dinfo(this.props)}
                {dinfo("state")} {dinfo(this.state)}
                <div style={{ marginLeft: this.props.arrow + '%' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0000FF" className="inline-block mb-[-8px]" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                    </svg>
                </div>
                <div className='border-[2px] rounded-md border-[#0000FF] px-2 pt-2'>

                    <>

                        {this.props.isControl &&
                            <div className='flex p-2'>
                                <div className='w-1/4'>Name</div>
                                <div className='w-3/4'>
                                    <input type="text" id="name" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.name || ""} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                                </div>

                            </div>
                        }


                        {this.props.isControl &&
                            <div className='flex p-2'>
                                <div className='w-1/4'>Type</div>
                                <div className='w-3/4'>

                                    <select id="type" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.type} onChange={this.onChange} onKeyDown={this.onKeyDown} >
                                        {/*<option value="0">Label</option>*/}
                                        <option value="4">Checkbox</option>
                                        <option value="6">Date</option>
                                        <option value="7">Email</option>
                                        <option value="8">Number</option>
                                        <option value="9">Password</option>
                                        <option value="3">Select</option>
                                        <option value="5">Radio</option>
                                        <option value="10">Tel</option>
                                        <option value="1">Text</option>
                                        <option value="2">TextArea</option>
                                        <option value="11">Time</option>
                                        <option value="12">URL</option>
                                    </select>
                                </div>
                            </div>
                        }


                        {this.state.q.type == 0 && this.renderLabel()}
                        {this.state.q.type == 2 && this.renderTextArea()}
                        {this.state.q.type == 3 && this.renderSelect()}
                        {this.state.q.type == 4 && this.renderText()}
                        {this.state.q.type == 5 && this.renderText()}
                        {this.state.q.type == 6 && this.renderText()}
                        {this.state.q.type == 7 && this.renderText()}
                        {this.state.q.type == 8 && this.renderText()}
                        {this.state.q.type == 9 && this.renderText()}
                        {this.state.q.type == 10 && this.renderText()}
                        {this.state.q.type == 11 && this.renderText()}
                        {this.state.q.type == 12 && this.renderText()}

                        {this.state.q.type == 3 && this.renderOptions()}
                        {this.state.q.type == 4 && this.renderOptions()}
                        {this.state.q.type == 5 && this.renderOptions()}

                        {this.props.isControl &&
                            <div className='flex p-2'>
                                <div className='w-1/4'>Required</div>
                                <div className='w-3/4 text-left'>
                                    <input type="checkbox" id="required" className="bg-[#F8F9FF] p-2 " checked={this.state.q.required || false} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                                </div>

                            </div>
                        }

                        {this.props.isControl &&
                            <div className='flex p-2'>
                                <div className='w-1/4'>Pattern</div>
                                <div className='w-3/4'>
                                    <input type="text" id="pattern" className="w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF] p-2 " value={this.state.q.pattern || ""} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                                </div>

                            </div>
                        }

                        <div className='text-center mt-2'>

                            <button className="border-x-[2px] border-t-[2px] rounded-t-md border-[#0000FF] px-2" onClick={() => this.onClose()}> Close </button>
                        </div>
                    </>
                </div>
            </div>
        );




    }

    public onChange = async (event: any) => {
        console.log("ControlEdit onChange", event.target.id, event.target.value);

        //this.props.onChange(event);

        const newq = { ...this.state.q };

        switch (event.target.id) {
            case "name": newq.name = event.target.value; break;
            case "type": newq.type = Number(event.target.value); break;
            case "label": newq.label = event.target.value; break;
            case "description": newq.description = event.target.value; break;
            case "required": newq.required = event.target.checked; break;
            case "pattern": newq.pattern = event.target.value; break;
        }

        console.log("ControlEdit onChange", newq);

        this.setState({
            q: newq
        });

    }

    public onOptionAdd = async () => {

        var options: Option[] = [...this.state.q.options];

        var newOption: Option = {
            value: "",
            text: ""
        }

        if (options != null) {

            options.push(newOption);
        }
        else {
            options = [newOption];
        }

        console.log("ControlEdit onOptionAdd", options);

        this.setState({
            q: { ...this.state.q, options: options }
        });

    }

    public onOptionDelete = async (index: number) => {

        var options: Option[] = [...this.state.q.options];


        if (options != null && options.length > index) {

            options = options.filter((option: Option, i: number) => i != index);
        }

        console.log("ControlEdit onOptionDelete", options);

        this.setState({
            q: { ...this.state.q, options: options }
        });

    }

    public onOptionChange = async (index: number, field: string, event: any) => {
        console.log("ControlEdit onOptionChange", event.target.id, event.target.value);

        var options: Option[] = [...this.state.q.options];

        console.log("ControlEdit options", options);



        options = options.map((option: Option, oindex: number) => {

            if (oindex == index) {
                switch (field) {
                    case "value": option.value = event.target.value; break;
                    case "text": option.text = event.target.value; break;
                }
                return option;
            }
            return option;
        });

        //set the value


        console.log("ControlEdit onOptionChange", options);

        this.setState({
            q: { ...this.state.q, options: options }
        });

    }

    public onClose = async () => {
        console.log("ControlEdit onClose");

        var q = this.state.q;
        //remove unused options
        if (q != null && q.options != null && q.options.length > 0) {

            q.options = q.options.filter((option: Option, i: number) => option.value != null && option.value.length > 0 && option.text != null && option.text.length > 0);
        }

        this.setState({
            q: { ...q }
        });

        this.props.onClose(this.props.identifier, this.props.pageIndex, this.props.sectionIndex, this.props.lineIndex, this.props.controlIndex, q);

    }

    public onKeyDown = async (event: any) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                //close
            });

        }


    }






}
