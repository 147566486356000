
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";
import En from "./components/En";
import LanguageDropdown from "./components/LanguageDropdown";
import Top1 from "./components/Top1";
import { LogoutURL, Picture, UserEmail, UserId, UserName } from "./objects";

const Layout = () => {
  const { t } = useTranslation();

  return (
    <>

      <div className="w-[260px] h-full shadow-md bg-[#313a46] absolute" id="sidenavSecExample">
        <div className="pt-4 pb-2 px-7">

          <div className="flex items-center">
            <div className="shrink-0">
              <Link to="/admin"><img src={require('./assets/shufflelabs.png')} className='inline w-[200px]' title="ShuffleForms" alt="ShuffleForms"></img></Link>
            </div>
          </div>

        </div>
        {(UserId != null && UserId.length > 0) &&
          <Top1 count={0} />
        }

        <div className="text-center bottom-0 absolute w-full">
          <hr className="m-0" />
          <p className="py-2 text-sm text-white">ShuffleForms</p>
        </div>
      </div>
      <div className="ml-[260px] h-m-full bg-[#fafbfe] text-[#6c757d]">


        <div className="h-[70px] leading-[70px] flex" >
          {(UserId != null && UserId.length > 0) &&
            <div className="w-full bg-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline-block w-8 h-8 m-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <div className="inline-block items-center">
                <label htmlFor="simple-search" className="sr-only"><En text="Search" /></label>
                <div className="inline-block ">

                  <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={t("Search") || "Search"} />
                </div>
                <button type="submit" className="p-2.5 ml-2 text-sm font-medium text-white bg-[#717cf4] rounded-sm   border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <span className=""><En text="Search" /></span>
                </button>
              </div>


              <div className="float-right mr-2">
              {Picture!=null && Picture.length>0 &&
                <img src={Picture} alt="image" className="inline h-[32px] mr-2"/>
              }
                {UserName} ({UserEmail}) <a href={LogoutURL}><En text="Logout" /></a></div>

              {/*<span className="float-right"><LanguageDropdown /></span>*/}

            </div>
          }
        </div>


        <Outlet />
      </div>

    </>
  )
};

export default withTranslation()(Layout);
