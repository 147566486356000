import * as React from 'react';
import { dinfo, Option, Question, Answer } from '../objects';
import Icons from './Icons';


interface ControlViewProps {

    allowEdit: boolean;

    identifier: string;
    pageIndex: number;
    sectionIndex: number;
    lineIndex: number;
    questionIndex: number;

    //title: string;
    //value: string;

    q: Question;

    isControl: boolean;

    onEdit: (identifier: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number) => void; //hide it
    onDelete: (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number) => void; //delete it

    onChange: (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, answer: Answer) => void;

}

interface ControlViewState {
    editMode: boolean,
    q: Question;
}



export default class ControlView extends React.PureComponent<ControlViewProps, ControlViewState> {
/*
    constructor(props: ControlViewProps) {
        super(props);
    }
*/
    public state: ControlViewState = {
        q: {
            ...this.props.q, answer:
            {
                identifier: this.props.q.identifier,
                name: this.props.q.name,
                options: [],
                response: ""
            }
        },
        editMode: false,
    };


    private renderLabel() {
        return (
            <>
                <div className="block w-full text-2xl font-bold" onClick={() => this.setEditMode()}>{this.props.q.label}</div>
                <div className="block w-full text-sm ml-2" onClick={() => this.setEditMode()}>{this.props.q.description}</div>
            </>
        );

    }



    private renderTextArea() {
        return (
            <>
                <span key="label" className='text-ellipsis overflow-hidden w-[100px]'>
                    {this.props.q.label}
                </span>

                <textarea className="delete2 p-2 w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF]"
                    id={this.state.q.identifier}
                    placeholder={this.props.q.placeHolder}
                    value={this.state.q.answer?.response}
                    onChange={this.onChange}
                    required={this.state.q.required}
                ></textarea>
                <p id={this.state.q.identifier + "-error"} className="error text-red-800"></p>
            </>

        );

    }

    private renderSelect() {
        return (
            <>
                <span key="label" className='text-ellipsis overflow-hidden w-[100px]'>
                    {this.props.q.label}
                </span>

                <select className="delete2 p-2 w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF]"
                    id={this.state.q.identifier}
                    required={this.state.q.required}
                    onChange={this.onChange}
                    >
                    <option>{this.props.q.placeHolder}</option>
                    {this.props.q.options != null && this.props.q.options.length > 0 &&
                        this.props.q.options.map((x: Option, index: number) =>
                            <option key={"o" + index} value={x.value}>{x.text}</option>
                        )
                    }
                </select>
                <p id={this.state.q.identifier + "-error"} className="error text-red-800"></p>
            </>

        );

    }

    private renderCheckbox() {
        return (
            <div className="">

                {/*dinfo(this.state.q)*/}
                {this.props.q.options != null && this.props.q.options.length > 0 &&
                    <>
                        <span key="label" className='text-ellipsis overflow-hidden w-[100px]'>
                            {this.props.q.label}
                        </span>
                        <p id={this.state.q.identifier + "_0-error"} className="error text-red-800"></p>
                        {this.props.q.options.map((x: Option, index: number) =>
                            <div key={"options" + index} className="ml-2">
                                <label key={"label" + index} htmlFor={this.props.q.identifier + "_" + index}>
                                    <input key={"option" + index}
                                        type="checkbox"
                                        name={"name" + this.props.q.identifier}
                                        id={this.props.q.identifier + "_" + index}
                                        value={x.value}
                                        required={this.state.q.required && index === 0 && !(this.state.q.answer.options != null && this.state.q.answer.options.length > 0)}
                                        className='mr-2'
                                        onInvalid={this.onInvalid}
                                        onChange={(e)=> {this.onOptionChange("checkbox",e) }}
                                    />
                                    {x.text}</label>
                            </div>


                        )}
                    </>
                }

                {!(this.props.q.options != null && this.props.q.options.length > 0) &&
                    <div>
                        <label key={"label"} htmlFor={this.props.q.identifier}>
                            <input key={"option"}
                            type="checkbox"
                            id={this.props.q.identifier}
                            value="on"
                            required={this.state.q.required}
                            className='mr-2'
                            //onInvalid={this.onInvalid}
                            onChange={this.onChange} />
                            {this.props.q.label}
                        </label>
                        <p id={this.state.q.identifier + "-error"} className="error text-red-800"></p>
                    </div>
                }
            </div>

        );
    }

    private renderRadio() {
        return (
            <div className="">

                {this.props.q.options != null && this.props.q.options.length > 0 &&
                    <>
                        <span key="label" className='text-ellipsis overflow-hidden w-[100px]'>
                            {this.props.q.label}
                        </span>
                        <p id={this.state.q.identifier + "_0-error"} className="error text-red-800"></p>
                        {this.props.q.options.map((x: Option, index: number) =>
                            <div key={"options" + index} className="ml-2">
                                <label key={"label" + index} htmlFor={this.props.q.identifier + "_" + index}>
                                    <input key={"option" + index}
                                        type="radio"
                                        name={"name" + this.props.q.identifier}
                                        id={this.props.q.identifier + "_" + index}
                                        value={x.value}
                                        required={this.state.q.required && index === 0 && !(this.state.q.answer.options != null && this.state.q.answer.options.length > 0)}
                                        className='mr-2'
                                        onInvalid={this.onInvalid}
                                        onChange={(e)=> {this.onOptionChange("radio",e) }}
                                    />
                                    {x.text}</label>
                            </div>
                        )}
                    </>
                }

                {!(this.props.q.options != null && this.props.q.options.length > 0) &&
                    <div>
                        <label key={"label"} htmlFor={this.props.q.identifier}>
                            <input key={"option"}
                            type="radio"
                            id={this.props.q.identifier}
                            value="on"
                            required={this.state.q.required}
                            className='mr-2'
                              //onInvalid={this.onInvalid}
                              onChange={this.onChange}
                            />
                            {this.props.q.label}
                        </label>
                    </div>
                }

            </div>

        );

    }

    private renderText(type: string) {
        return (
            <>
                <span key="label" className='text-ellipsis overflow-hidden w-[100px]'>
                    {this.props.q.label}
                </span>

                <input type={type}
                    id={this.state.q.identifier}
                    className="delete2 p-2 w-full rounded-md border-[1px] border-[#CCCCCC] bg-[#F8F9FF]"
                    placeholder={this.props.q.placeHolder}
                    value={this.state.q.answer?.response}
                    onChange={this.onChange}
                    required={this.state.q.required}
                />

                <p id={this.state.q.identifier + "-error"} className="error text-red-800"></p>
            </>

        );
    }



    public render() {

        let control;
        switch (this.props.q.type) {
            case 0: control = this.renderLabel(); break;

            case 1: control = this.renderText("text"); break;
            case 2: control = this.renderTextArea(); break;
            case 3: control = this.renderSelect(); break;
            case 4: control = this.renderCheckbox(); break;

            case 5: control = this.renderRadio(); break;
            case 6: control = this.renderText("date"); break;
            case 7: control = this.renderText("email"); break;
            case 8: control = this.renderText("number"); break;

            case 9: control = this.renderText("password"); break;
            case 10: control = this.renderText("tel"); break;
            case 11: control = this.renderText("time"); break;
            case 12: control = this.renderText("url"); break;


            default:
                //control = this.renderText(); break;
                control = <>{dinfo(this.props)}</>
        }


        return (
            <React.Fragment>
                <div className='delete1 '> {/*hover:border-[1px] border-[#0000FF]*/}

                    {this.props.allowEdit &&
                        <span className="hidden1 float-right" onClick={() => this.setEditMode()}> <Icons type={"edit"} title={"Edit " + this.props.identifier} /></span>
                    }
                    {this.props.allowEdit &&
                        <>
                            <span className="hidden1 float-left" onClick={() => { this.props.onDelete(this.props.pageIndex, this.props.sectionIndex, this.props.lineIndex, this.props.questionIndex) }}> <Icons type={"trash"} title={"Delete " + this.props.identifier} /></span>
                            {/*<span className="hidden1 float-right" onClick={() => { todo();}}> <Icons type={"move"} title={"Move " + this.props.identifier} /></span>*/}
                        </>
                    }
                    <div className="">
                        {this.props.isControl &&
                            <>

                                {this.props.q.description != null && this.props.q.description.length > 0 &&
                                    <span key="description" className="float-right"><Icons type={"help"} title={this.props.q.description} /></span>
                                }
                            </>
                        }
                    </div>
                    {control}


                </div>

                {/*dinfo(this.props)*/}
            </React.Fragment>
        );


    }

    public setEditMode = async () => {

        if (this.props.allowEdit) {
            this.props.onEdit(this.props.identifier, this.props.pageIndex, this.props.sectionIndex, this.props.lineIndex, this.props.questionIndex);
        }
    }

    public onChange = async (event: any) => {
        console.log("ControlEdit onChange", event.target.value);

        var answer: Answer = this.state.q.answer;
        answer.response = event.target.value;
        console.log("ControlEdit onChange", answer);

        var field: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;
        if (field != null) field.checkValidity();

        this.setState({
            q: { ...this.state.q, answer: { ...answer } }
        });

        this.props.onChange(this.props.pageIndex, this.props.sectionIndex, this.props.lineIndex, this.props.questionIndex, this.state.q.answer);

    }

    public onInvalid = async (event: any) => {

        var field: HTMLFormElement = document.getElementById(event.target.id) as HTMLFormElement;

        if (field != null) {
            if (this.state.q.required && !(this.state.q.answer.options != null && this.state.q.answer.options.length > 0)) {
                field.setCustomValidity('Please select at least one option to proceed.')
            }
            else {
                field.setCustomValidity('');
            }
            //field.checkValidity();loop
        }

    }

    public onOptionChange = async (type: string, event: any) => {
        console.log("ControlEdit onChange", event.target.value);

        var answer: Answer = this.state.q.answer;

        if (answer.options == null) answer.options = [];

        if (type === "checkbox") {
            if (event.target.checked) {
                //add it if not there
                if (answer.options.filter(x => x.value === event.target.value).length > 0) {
                    //already there
                }
                else {
                    var option = this.state.q.options.filter(x => x.value === event.target.value);
                    if (option != null && option.length > 0 && option[0] != null) {
                        answer.options.push(option[0]);
                    }
                }
            }
            else {
                //remove it if there
                answer.options = answer.options.filter(x => x.value !== event.target.value);
            }
        }

        if (type === "radio") {
            if (event.target.checked) {
                    var option2 = this.state.q.options.filter(x => x.value === event.target.value);
                    if (option2 != null && option2.length > 0 && option2[0] != null) {
                        answer.options = [option2[0]];
                    }

            }
            else {
                //remove it if there
                answer.options = answer.options.filter(x => x.value !== event.target.value);
            }
        }

        console.log("ControlEdit onChange", answer);

        var field: HTMLFormElement = document.getElementById(this.state.q.identifier + "_0") as HTMLFormElement;

        if (field != null) {
            if (this.state.q.required && !(this.state.q.answer.options != null && this.state.q.answer.options.length > 0)) {
                field.setCustomValidity('Please select at least one option to proceed')
            }
            else {
                field.setCustomValidity('');
            }
            field.checkValidity();
        }

        var f: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;
        if (f != null) f.checkValidity();

        this.setState({
            q: { ...this.state.q, answer: { ...answer } }
        });

        this.props.onChange(this.props.pageIndex, this.props.sectionIndex, this.props.lineIndex, this.props.questionIndex, this.state.q.answer);




    }


}
