import React, { Component } from "react";
import FormEdit from "../components/FormEdit";

import { withRouter, WithRouterProps } from './withRouter';

interface Params {
    identifier: string;
}

type Props = WithRouterProps<Params>;

class EditFormPage extends Component<Props> {

    render() {
        const { match, navigate } = this.props;
        console.log(match.params.identifier); // with autocomplete
        return (
            <>
               <FormEdit identifier={match.params.identifier} navigate={navigate} />
            </>

        );
    }
}

export default withRouter(EditFormPage);