





export interface BaseResponse {
  errorLevel: number;
  errorMessage: string;
}

export interface GoogleSheetInfo {
  docName: string;
  sheetNames: string[];
  errorLevel: number;
  errorMessage: string;
}

export interface IntegrationProperty {
  name: string;
  label: string;
  value: string;
}

export interface Integration {
  id: number;
  formId: number;
  formIdentifier: string;
  userId: number;
  type: string;
  setup: IntegrationProperties;
}

export interface IntegrationProperties {
  properties: IntegrationProperty[];
}

export interface FieldType {
  name: string;
  id: number;
}

export interface BasePageList {
  pageNumber: number;
  totalPages: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  count: number;
  totalCount: number;
}

export interface Option {
  value: string;
  text: string;
}

export interface Question {
  name: string;
  identifier: string;
  type: number;
  label: string;
  description: string;
  placeHolder: string;
  required: boolean;
  min: string;
  max: string;
  step: string;
  pattern: string;

  options: Option[];
  //runtime
  answer: Answer;

}

export interface Line {
  questions: Question[];
}


export interface Section {
  label: string;
  description: string;
  lines: Line[];
}

export interface Page {
  label: string;
  description: string;
  sections: Section[];
}

export interface Structure {
  label: string;
  description: string;
  pages: Page[];
}

export interface Form {
  id: number;
  identifier: string;
  modifiedDate: string;
  isVersion: boolean;
  startDate: string;
  endDate: string;
  label: string;

  setup: Structure;

  //json:string;
}

export interface Answer {
  identifier: string;
  name: string;
  response: string;
  options: Option[];
}


export interface IResponse {
  id: number;
  //  identifier: string;
  formId: number;
  formIdentifier: string;
  ip: string;
  modifiedDate: string;

  answers: Answer[];
}

export interface FormsList extends BasePageList {
  forms: Form[];
}

export interface ResponseLine {
  cells: string[];

}
export interface ResponsesList extends BasePageList {
  columns: string[];
  lines: ResponseLine[];
}

var baseapi = "/api/" as string

console.log("window.location.href", window.location.href);
if (window.location.href.indexOf(':3000') > 0) baseapi = 'https://localhost:7288/api/';

export const BASEAPI: string = baseapi;


var hubapi = "/hub/" as string

console.log("window.location.href", window.location.href);
if (window.location.href.indexOf(':3000') > 0) hubapi = 'https://localhost:7288/hub/';

export const HUBAPI: string = hubapi;


export const dinfo = (data: any) => {
  //alert(window.location.href);
  if (window.location.href != null && window.location.href.indexOf("localhost") > 0) {
    return JSON.stringify(data, null, "\t");
  }
  else {
    return "";
  }
};


export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}


export const w = (length: number) => {
  return "w-[" + Math.floor(100 / length) + "%]";
};


export const UserName: string = (document.getElementById('UserName') as HTMLInputElement).value as string;
export const UserEmail: string = (document.getElementById('UserEmail') as HTMLInputElement).value as string;
export const UserId: string = (document.getElementById('UserId') as HTMLInputElement).value as string;
export const Picture: string = (document.getElementById('Picture') as HTMLInputElement).value as string;
export const GoogleURL: string = (document.getElementById('GoogleURL') as HTMLInputElement).value as string;
export const MicrosoftURL: string = (document.getElementById('MicrosoftURL') as HTMLInputElement).value as string;
export const LogoutURL: string = (document.getElementById('LogoutURL') as HTMLInputElement).value as string;

export const todo = () => {
  alert('not implemented');
};

export const defaultButtons: Question[] = [{
  type: 1,
  label: "Text",
  description: "text",
  identifier: "text",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "text",
  answer: { name: "text", identifier: "text", options: [], response: "" }
},
{
  type: 2,
  label: "Textarea",
  description: "textarea",
  identifier: "textarea",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "textarea",
  answer: { name: "textarea", identifier: "textarea", options: [], response: "" }
},
{
  type: 3,
  label: "Select",
  description: "select",
  identifier: "select",
  options: [{ value: "1", text: "Option1" }, { value: "2", text: "Option2" }],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "select",
  answer: { name: "select", identifier: "select", options: [], response: "" }
},
{
  type: 4,
  label: "Checkbox",
  description: "checkbox",
  identifier: "checkbox",
  options: [{ value: "1", text: "Checkbox1" }, { value: "2", text: "Checkbox2" }],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "checkbox",
  answer: { name: "checkbox", identifier: "checkbox", options: [], response: "" }
},
{
  type: 5,
  label: "Radio",
  description: "radio",
  identifier: "radio",
  options: [{ value: "1", text: "Radio1" }, { value: "2", text: "Radio2" }],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "radio",
  answer: { name: "radio", identifier: "radio", options: [], response: "" }
},
{
  type: 6,
  label: "Date",
  description: "date",
  identifier: "date",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "date",
  answer: { name: "date", identifier: "date", options: [], response: "" }
},
{
  type: 7,
  label: "Email",
  description: "email",
  identifier: "email",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "email",
  answer: { name: "email", identifier: "email", options: [], response: "" }
},
{
  type: 8,
  label: "Number",
  description: "number",
  identifier: "number",
  options: [],
  min: "0",
  max: "100",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "1",
  name: "question",
  answer: { name: "number", identifier: "number", options: [], response: "" }
},
{
  type: 9,
  label: "Password",
  description: "password",
  identifier: "password",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "password",
  answer: { name: "password", identifier: "password", options: [], response: "" }
},
{
  type: 10,
  label: "Tel",
  description: "tel",
  identifier: "tel",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "tel",
  answer: { name: "tel", identifier: "tel", options: [], response: "" }
},
{
  type: 11,
  label: "Time",
  description: "time",
  identifier: "time",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "",
  step: "",
  name: "time",
  answer: { name: "time", identifier: "time", options: [], response: "" }
},
{
  type: 12,
  label: "URL",
  description: "url",
  identifier: "url",
  options: [],
  min: "",
  max: "",
  pattern: "",
  required: false,
  placeHolder: "https://",
  step: "",
  name: "url",
  answer: { name: "url", identifier: "url", options: [], response: "" }
}
]
