import * as signalR from "@microsoft/signalr";
import { HUBAPI } from "./objects";
const URL = process.env.HUB_ADDRESS ?? HUBAPI; //or whatever your ShuffleForms port is
class Connector {
    private connection: signalR.HubConnection;
    public events: (onMessageReceived: (username: string, message: string) => void) => void;
    static instance: Connector;


    constructor(user: string) {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL)
            .withAutomaticReconnect()
            .build();
        //this.connection.start().catch(err => document.write(err));

        this.start(user);


        console.log("this.connection.", this.connection.state, user);

        this.events = (onMessageReceived) => {
            this.connection.on("messageReceived", (username, message) => {
                onMessageReceived(username, message);
            });
        };
    }

     public async start(user:string) {
    try {
        await this.connection.start();
        console.log("SignalR Connected.", user);

        this.connection.send("subscribe", user).then(x => console.log("subscribe sent ", user))


    } catch (err) {
        console.log(err);
    }
};


    public newMessage = (user: string, messages: string) => {
        this.connection.send("newMessage", user, messages).then(x => console.log("sent", user))
    }

    public static getInstance(user: string): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector(user);
            console.log("Connector.instance",Connector.instance);
        return Connector.instance;
    }
}

export default Connector.getInstance;