import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import DashboardPage from './pages/DashboardPage';
import EditFormPage from './pages/EditFormPage';
import PreviewPage from './pages/PreviewPage';
import NoPage from './pages/NoPage';
import Layout from "./Layout";
import Integrations from './pages/IntegrationsPage';
import DataPage from './pages/DataPage';
import Reports from './pages/ReportsPage';
import React from 'react';
import { UserId } from './objects';
import LoginPage from './pages/LoginPage';
import UserPage from './pages/UserPage';



function App() {

    if (UserId != null && UserId.length > 0) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<UserPage />} />
                    <Route path="/:identifier" element={<UserPage />} />
                    <Route path="admin/preview/:identifier" element={<PreviewPage />} />
                    <Route path="/admin" element={<Layout />}>
                        <Route index element={<DashboardPage />} />
                        <Route path="/admin/form/:identifier" element={<EditFormPage />} />
                        <Route path="/admin/integrations" element={<Integrations />} />
                        <Route path="/admin/integrations/:identifier" element={<Integrations />} />
                        <Route path="/admin/data" element={<DataPage />} />
                        <Route path="/admin/reports" element={<Reports />} />

                    </Route>
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<UserPage />} />
                    <Route path="/:identifier" element={<UserPage />} />
                    <Route path="/admin" element={<Layout />}>
                        <Route index element={<LoginPage />} />
                    </Route>
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;