import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../App.css';
import { UserId } from '../objects';
import Connector from '../signalr-connection'

type SignalProps = {
    callback: ((message:string) => void);
  };


function Signal(c : SignalProps) {
    const [message, setMessage] = useState("initial value");


/*
    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.get("user") != null) {
        var uu = searchParams.get("user");
        if (uu != null) {
            u = uu.toString();
        }
    }
*/
    const [user, setUser] = useState(UserId);
    const { newMessage, events } = Connector(user);

    useEffect(() => {
        events((_, message) => {
            console.log(message);
            c.callback(message);
            //setMessage(message);
        });
    });
    return (
        <div>
            {/*searchParams.get("user") */}
             <span className='text-white'>message from signalR: {message}</span>

            </div>

    );
}
export default Signal;