import React from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BASEAPI, Form, FormsList } from '../objects';
import saveAs from "file-saver";
import PagePreviewControl from "../components/PagePreviewControl";
import Generate from "../components/Generate";
import En from "../components/En";
//npm install -save file-saver and npm i --save-dev @types/file-saver

interface DashboardPageProps {
    //callback: (() => void);
}
interface DashboardPageState {
    data: FormsList,

}

const defaultState: FormsList = {
    forms: [],
    pageNumber: 0,
    totalPages: 0,
    isFirstPage: false,
    isLastPage: false,
    count: 0,
    totalCount: 0
}


export default class DashboardPage extends React.PureComponent<DashboardPageProps, DashboardPageState> {
    /*
    constructor(props: DashboardPageProps) {
        super(props);
    }
    */
    public state: DashboardPageState = {
        data: defaultState,
    };

    public componentDidMount() {

        axios.get<FormsList>(BASEAPI + `GetForms?page=1&pagesize=10`)
            .then(data => {
                //if no errors
                this.setState({
                    data: data.data,
                });
            });

    }

    private Download = (identifier: string) => {

        if (identifier != null && identifier.length > 0) {
            axios({
                url: BASEAPI + `GetResponseCSV?FormIdentifier=${identifier}`, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {

                console.log("response", response);

                saveAs(response.data, identifier + ".csv");

            });
        }
        else {
            alert("identifier not provided")
        }

    }


    private Delete = (identifier: string) => {

        if (identifier != null && identifier.length > 0 && window.confirm('You are sure you want to delete this form?')===true) {

            axios.delete<FormsList>(BASEAPI + `DeleteForm?Identifier=${identifier}`)
            .then(data => {
                //if no errors
                this.setState({
                    data: data.data,
                });
            });

        }
        else {
            alert("identifier not provided")
        }

    }


    public render() {
        return (
            <>

                <div className="container ml-5">
                    <p className="text-2xl font-bold my-5">
                       <En text="Dashboard"/>
                    </p>


                        <Generate prop={""} />


                        <p className="text-2xl font-bold my-5">
                        <En text="My Forms"/>
                        </p>

                    <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border p-8 ">



                        {this.state.data != null && this.state.data.forms != null && this.state.data.forms.length > 0 &&
                            <div className="flex flex-wrap bg-white overflow-auto">
                                {this.state.data.forms.map((record: Form, index: number) =>
                                    <div key={"div" + index} className="w-1/3 p-3 flex flex-col min-h-[30rem]">
                                        <div className="flex-1 border-[1px] rounded-sm  shadow-md shadow-[#DDDDDD] border p-8 m-10 border-[#CCCCCC] mb-5">
                                            <Link key={"link" + index} className="flex-1 text-[#d52162] " to={{ pathname: "/admin/form/" + record.identifier }} title='View'>
                                                <PagePreviewControl key={"pagep" + index} index={index} page={record.setup.pages[0]} /> {/*page={{...page, sections: {...page.sections.filter((x,index)=> index==0)} }} */}
                                            </Link>

                                        </div>
                                        <div className="w-full px-10 text-center">
                                        <button className="float-left text-[#CCCCCC] mx-2" onClick={() => this.Delete(record.identifier)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>
                                            </button>

                                            {record.label}
                                            <button className="float-right text-[#CCCCCC] mx-2" onClick={() => this.Download(record.identifier)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>

                {/*dinfo(this.state.data)*/}
            </>
        );
    }




}
