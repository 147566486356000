import React from "react";
import axios from 'axios';

import { BASEAPI, Form, FormsList, ResponseLine, ResponsesList } from '../objects';
import En from "./En";

interface DataProps {
    //callback: (() => void);
    message: string;
}
interface DataState {
    forms: FormsList;

    formIdentifier: string;

    needsData: boolean;
    data: ResponsesList,

    pageSize: number;
    currentPage: number;

    message: string;
}

const defaultResponsesState: ResponsesList = {
    columns: [],
    pageNumber: 0,
    totalPages: 0,
    isFirstPage: false,
    isLastPage: false,
    count: 0,
    lines: [],
    totalCount: 0
}

const defaultFormsState: FormsList = {
    forms: [],
    pageNumber: 0,
    totalPages: 0,
    isFirstPage: false,
    isLastPage: false,
    count: 0,
    totalCount: 0
}


export default class Data extends React.PureComponent<DataProps, DataState> {
    /*constructor(props: DataProps) {
        super(props);
    }*/
    public state: DataState = {
        data: defaultResponsesState,
        forms: defaultFormsState,
        formIdentifier: "",
        needsData: false,
        pageSize: 10,
        currentPage: 0,
        message: ""
    };


    private Refresh = () => {

        if (this.state.needsData && this.state.formIdentifier != null && this.state.formIdentifier.length > 0) {
            axios.get<ResponsesList>(BASEAPI + `GetResponses` + "?page="+(this.state.currentPage+1)+"&pagesize="+this.state.pageSize+"&identifier=" + this.state.formIdentifier)
                .then(data => {
                    //if no errors
                    this.setState({
                        data: data.data,
                        needsData: false
                    });


                });
        }
    }

    public componentDidMount() {

        axios.get<FormsList>(BASEAPI + `GetForms` + "?page=1&pagesize=10")
            .then(data => {
                //if no errors
                this.setState({
                    forms: data.data,

                });

                if (data.data != null && data.data.forms != null && data.data.forms.length > 0 && data.data.forms[0] != null) {
                    this.setState({
                        formIdentifier: data.data.forms[0].identifier,
                        needsData: true
                    });
                }

            });

        this.Refresh();

    }

    public componentDidUpdate() {

        if (this.state.formIdentifier!=null && this.state.formIdentifier.length>0
            &&  this.props.message.indexOf(this.state.formIdentifier)>=0 &&  this.props.message!=this.state.message
            ) {
            this.setState({
                message: this.props.message,
                needsData:true,

            });

        }

        this.Refresh();

    }

    private onFormSelect = (event: any) => {
        //event.preventDefault();

        const value: string = event.target.value;

        console.log("onFormSelect", event, event.target.value);
        //event.dataTransfer.setData("text/plain", event.target.id);

        this.setState({
            formIdentifier: value,
            needsData: true,
        });

    }


    private onPageSize = (event: any) => {
        //event.preventDefault();

        const value: number = Number(event.target.value);

        console.log("onPageSize", event, event.target.value);
        //event.dataTransfer.setData("text/plain", event.target.id);

        this.setState({
            pageSize: value,
            needsData: true,
        });

    }
    private onPage = (nr: number) => {
        //event.preventDefault();


        console.log("onPage", nr);
        //event.dataTransfer.setData("text/plain", event.target.id);

        this.setState({
            currentPage: nr ,
            needsData: true,
        });

    }


    private AddFakeData = () => {



        //axios.post<boolean>(BASEAPI + `AddResponse` + "?FormId=1&Identifier=form1")
        axios.get<boolean>(BASEAPI + `FakeResponse` + '?Identifier=' + this.state.formIdentifier)
            .then(data => {
                //if no errors
                /*this.setState({
                    data: data.data,
                });
                */
                //alert('random data submited');

                if (data.data === false) {
                    alert('An eror occured!!!');
                }
                else {

                    this.setState({

                        needsData: true,
                    })
                    this.Refresh();



                }
            });


    }


    public render() {
        return (
            <>
            <p className="text-2xl font-bold my-5">
                        <En text="Data" />
                    </p>

                <div className=" bg-white rouded-md shadow-2xl p-4">

                    <div className="p-2">

                        <span key="" className='p-4 '>
                            Show <select className='pl-2 select80 ' defaultValue={this.state.pageSize} onChange={this.onPageSize} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                            </select>
                            entries
                        </span>

                        <div className="float-right">

                    <select id="forms" className="border-[1px] border-[#CCCCCC] rounded-sm p-2" onChange={this.onFormSelect}>


                        {this.state.forms.forms.map((form: Form, index: number) =>
                            <option key={"form"+index} value={form.identifier}>{form.label}</option>
                        )}
                    </select>

                </div>

                    </div>
                    <table className="w-[100%] text-sm text-left mt-5 block overflow-x-auto">
                        {this.state.data != null && this.state.data.columns != null && this.state.data.columns.length > 0 &&
                            <thead className="text-xs bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr className="py-10">
                                    {this.state.data.columns.map((column: string, index1: number) =>
                                        <th key={"col" + index1} scope="col" className="px-2 py-4">{column}</th>
                                    )}
                                </tr>
                            </thead>
                        }

                        {this.state.data != null && this.state.data.lines != null && this.state.data.lines.length > 0 &&
                            <tbody>
                                {this.state.data.lines.map((record: ResponseLine, index2: number) =>
                                    <tr key={"row" + index2} className="border-b even:bg-gray-50">

                                        {record.cells.map((cell: string, index3: number) =>
                                            <td key={"cell" + index3} className="px-2 py-4 text-ellipsis">

                                                {cell}

                                            </td>

                                        )}



                                    </tr>
                                )}

                            </tbody>
                        }




                    </table>
                    <div className="p-2">
                        <En text="Showing" /> {this.state.currentPage * this.state.pageSize+1} <En text="to" /> {this.state.currentPage * this.state.pageSize + this.state.data.count} <En text="of" /> {this.state.data.totalCount} <En text="entries" />

{this.state.data !=null &&
                        <span className="float-right">

                        {!this.state.data.isFirstPage && <span key={"pagePrevious"} className="px-2 cursor-pointer">Previous</span>}

                            {this.state.data.totalPages>0 &&
                                Array.from(Array(this.state.data.totalPages).keys()).map((nr: number, index: number) =>
                                    <span key={"page" + index} className="px-2 cursor-pointer" onClick={()=> this.onPage(nr)}>{nr + 1}</span>
                                )
                            }
                            {!this.state.data.isLastPage && <span key={"pageNext"} className="px-2 cursor-pointer">Next</span>}
                        </span>
                    }

                    </div>


                </div>
                <div className="text-right mt-5"> <button className="bg-[#39afd1] rounded-md text-[#FFFFFF] py-2 px-5 mx-10" onClick={() => this.AddFakeData()} >Add Fake Data</button>
                </div>

                <span className="message">{this.props.message}</span>


                {/*dinfo(this.state.data)*/}
            </>
        );
    }




}
