import React, { Component } from "react";
import Preview from "../components/Preview";

import { withRouter, WithRouterProps } from './withRouter';
import { Link } from "react-router-dom";

interface Params {
    identifier: string;
}

type Props = WithRouterProps<Params>;

class PreviewPage extends Component<Props> {
    render() {
        const { match } = this.props;
        console.log(match.params.identifier); // with autocomplete
        return (
            <>
            {(match.params.identifier!=null && match.params.identifier.length>0) &&

               <Preview identifier={match.params.identifier} isAdmin={true} />

            }

{!(match.params.identifier!=null && match.params.identifier.length>0) &&
<div className="text-center">
<Link to="/admin" className="m-2 font-bold text-red-400" >Admin</Link>
</div>
}
            </>

        );
    }
}

export default withRouter(PreviewPage);