import React from "react";
import { GoogleURL, MicrosoftURL } from "../objects";

const LoginPage = () => {
    return (
        <>
            <div className="container ml-5">
                    <p className="text-2xl font-bold m-10 ">

                    <a href={GoogleURL} className="text-[#313a46] border-[1px] border-[#CCCCCC] rounded-md shadow-xl p-4 m-4"  title="Login With Google" >
                    Login With Google
                                        </a>

                                        <a href={MicrosoftURL} className="text-[#313a46] border-[1px] border-[#CCCCCC] rounded-md shadow-xl p-4 m-4"  title="Login With Microsoft" >
                    Login With Microsoft
                                        </a>



                    </p>
                    </div>
        </>
    );
};

export default LoginPage;