import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import React from 'react';

type IProps = {
    url: string;
  };


function Navigate(props: IProps) {

const navigate = useNavigate();

useEffect(() => {
  navigate(props.url);
}, [navigate, props.url]);




return (
	<Link className="w-1/3 h-min-fit text-[#d52162]" to={{ pathname: props.url }} title='Redirect'></Link>
);
}

export default Navigate;
