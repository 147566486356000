import React from "react";
import En from "../components/En";

const ReportsPage = () => {
    return (
        <>

            <div className="container ml-5">
                <p className="text-2xl font-bold my-5">
                    <En text="Reports" />
                </p>



                <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border p-8 my-5">
{/*
<CheckboxGroup />
 */}

                </div>
            </div>



        </>
    );
};

export default ReportsPage;