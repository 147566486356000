import React, { Component } from "react";
import FormEdit from "../components/FormEdit";

import { withRouter, WithRouterProps } from './withRouter';
import GoogleSheets from "../components/GoogleSheets";
import { BASEAPI, dinfo, Integration } from "../objects";
import Navigate from "../components/Navigate";
import axios from "axios";

interface Params {
    identifier: string;
}

type Props = WithRouterProps<Params>;


interface IState {
    integrations: Integration[];
    needData: boolean;
}



class IntegrationsPage extends Component<Props, IState> {


    public state: IState = {
        needData: true,
        integrations: [],
    };

    public componentDidMount() {

        const { match, navigate } = this.props;
        console.log("identifier", match.params.identifier); // with autocomplete

        if (this.state.needData) {
            axios.get<Integration[]>(BASEAPI + `GetIntegrations?id=${match.params.identifier}`)
                .then(data => {

                    console.log("GetIntegrations", data.data);
                    this.setState({
                        integrations: data.data,
                        needData: false
                    });

                }).finally(() => {
                    /*this.setState({
                        commandprocessing: false,
                    });
                    */
                }
                );

        }

    }






    render() {



        const { match, navigate } = this.props;
        console.log("identifier", match.params.identifier); // with autocomplete




        var googleIntegration: Integration;

        var googleIntegrations = this.state.integrations.filter(x => x.type === "googlesheets");

        if (googleIntegrations != null && googleIntegrations.length > 0 && googleIntegrations[0] != null) {

            googleIntegration = googleIntegrations[0];
        }
        else {
            googleIntegration = {
                id: 0,
                formId: 0,
                formIdentifier: match.params.identifier,
                userId: 0,
                type: "googlesheet",
                setup: {
                    properties: [
                        {
                            name: "ID",
                            label: "Google Document ID",
                            value: ""
                        },
                        {
                            name: "docName",
                            label: "Google Document Name",
                            value: ""
                        },
                        {
                            name: "sheetName",
                            label: "Google Sheet Name",
                            value: ""
                        }

                    ]
                }
            }

        }


        return (
            <>
                {!(match.params.identifier != null && match.params.identifier.length > 0) &&
                    <>

                        <Navigate url={'/admin'} />
                    </>
                }

                <GoogleSheets navigate={navigate} integration={googleIntegration} />

                {dinfo(this.state)}
            </>

        );
    }
}

export default withRouter(IntegrationsPage);