import i18next from "i18next";
import HttpShuffleForms from "i18next-http-backend"; //this is in https://app.i18nexus.com/ site
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const apiKey = "CAVzBjLt8VXTiSRq9AMFEw";
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpShuffleForms)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["default"],
    defaultNS: "default",

    supportedLngs: ["en","fr","es", "hi", "ro"],

    backend: {
      loadPath: loadPath
    }
  })

//npm install i18next react-i18next i18next-http-ShuffleForms i18next-browser-languagedetector --save

//<a href='https://www.geeksforgeeks.org/multi-language-support-in-reactjs/'>Multi-Language Support in ReactJS</a>
//https://i18nexus.com/react-tutorial/
//https://app.i18nexus.com/
//https://app.i18nexus.com/projects/5Ax6xq2r1oq24Pwdx4htcC/strings
//alex.dimitriev@shufflelabs.com
//eC0rE2cX4bK1vV4o!
//https://react.i18next.com/latest/using-with-hooks