import * as React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ITopProps extends WithTranslation {
  text: string;
}

interface IState {

}

class Top1 extends React.Component<ITopProps, IState> {

  public render() {
    return (
      <React.Fragment>
       {this.props.t(this.props.text)}
      </React.Fragment>
    );
  }
};


export default withTranslation()(Top1);