import React from "react";
import Signal from "../components/Signal";
import Data from "../components/Data";


interface DataPageProps {
    //callback: (() => void);
}
interface DataPageState {
    //data: ResponsesList,
    message: string;

}

export default class DataPage extends React.PureComponent<DataPageProps, DataPageState> {
    /*constructor(props: DataPageProps) {
        super(props);
    }*/
    public state: DataPageState = {
        message: "",
    };


    private Refresh = (message: string) => {

        console.log("Refresh");
        this.setState({
            message: message,
        });

    }

    public componentDidMount() {



    }



    public render() {
        return (
            <>

                <div className="container px-5">




                    <Data message={this.state.message} />
                </div>



                <Signal callback={this.Refresh} />
                <hr />
                {/*dinfo(this.state)*/}
            </>
        );
    }




}
