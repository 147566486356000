import * as React from 'react';
import { Link, NavLink } from 'react-router-dom'; //https://reactrouter.com/en/main/components/nav-link
import { withTranslation, WithTranslation } from 'react-i18next';

interface ITopProps extends WithTranslation {
  count: number;
}


interface IState {
  state: string;
}

class Top1 extends React.Component<ITopProps, IState> {
  /*
  constructor(props: ITopProps) {
      super(props)
      //console.log("props", props);
  }
  */

  private colors(link: number) {
    if (link == this.props.count) {
      return "bg-[#FFFFFF] text-[#000000] ";
    }
    else {
      return "bg-[#301AB6] text-[#FFFFFF]";
    }
  }

  private icon(link: number) {
    if (link == this.props.count) {
      return require('../assets/1.png')
    }
    else {
      return require('../assets/0.png')
    }
  }

  public render() {

    return (
      <React.Fragment>

        <ul className="relative px-4 mt-5">
          <li className="relative">

            <NavLink to="/admin" end className={({ isActive, isPending }) =>
              (isActive ? "text-white" : "text-[#bccee4]") + " flex items-center text-sm py-4 px-6 h-12 overflow-hidden  text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"}

            >

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>

              <span className="ml-5 ">{this.props.t("Dashboard")}
              </span>
            </NavLink>
          </li>
          {/*
          <li className="relative">
            <Link to="/admin/integrations" className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" >

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
              </svg>

              <span className="ml-5">{this.props.t("Integrations")}</span>

            </Link>
          </li>
*/}

          <li className="relative">
            <NavLink to="/admin/data" end className={({ isActive, isPending }) =>
              (isActive ? "text-white" : "text-[#bccee4]") + " flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"}>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </svg>

              <span className="ml-5">{this.props.t("Data")}</span>

            </NavLink>
          </li><li className="relative">
            <NavLink to="/admin/reports" end className={({ isActive, isPending }) =>
              (isActive ? "text-white" : "text-[#bccee4]") + " flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"} >

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>

              <span className="ml-5">{this.props.t("Reports")}</span>

            </NavLink>
          </li>


        </ul>
      </React.Fragment>
    );
  }
};


export default withTranslation()(Top1);