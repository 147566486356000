import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BASEAPI, defaultButtons, dinfo, Form, Line, Page, Question, Section } from "../objects";
import axios from "axios";
import { Link } from "react-router-dom";
import PageEditControl from "./PageEditControl";


interface MyDataProps extends WithTranslation {
    //callback: (() => void);
    identifier: string;
    navigate: any;
}
interface MyDataState {
    //data: ResponsesList,

    form: Form;
    currentPage: number;

    buttons: Question[];

    //command: string;
    results: Question[];
    needResults: boolean;
    commandprocessing: boolean;

    moveid: string;
    publishURL: string;

}

const defaultForm: Form = {
    id: 0,
    identifier: "",
    isVersion: false,
    label: "",
    modifiedDate: "",
    startDate: "",
    endDate: "",
    setup: {
        label: "",
        description: "",
        pages: [],
    }
}




class FormEdit extends React.Component<MyDataProps, MyDataState> {
    /*
    constructor(props: MyDataProps) {
        super(props);
    }
*/
    public state: MyDataState = {
        form: defaultForm,
        currentPage: 0,
        //command: "",
        buttons: defaultButtons,
        results: [],
        needResults: true,
        moveid: "",
        commandprocessing: false,
        publishURL: ""
    };


    public componentDidMount() {

        this.setState({
            commandprocessing: true,
        });

        axios.get<Form>(BASEAPI + `GetFormPreview` + "?Identifier=" + this.props.identifier)
            .then(data => {
                //if no errors
                //data.data.json="{}";
                this.setState({
                    form: data.data,
                });

                if (this.state.needResults) {
                    this.setState({
                        needResults: false,
                    });

                    axios.get<Question[]>(BASEAPI + `CallGPT3` + "?id=" + data.data.id + "&q=" + data.data.label + "&useCache=true")
                        .then(data2 => {
                            //if no errors
                            this.setState({
                                results: data2.data,
                            });
                        }).finally(() => {
                            this.setState({
                                commandprocessing: false,
                            });
                        }
                        );
                }

            });



    }

    private onCallGPT = () => {

        this.setState({
            commandprocessing: true,
        });


        console.log("FormEdit onCallGPT");
        axios.get<Question[]>(BASEAPI + `CallGPT3` + "?id=" + this.state.form.id + "&q=" + this.state.form.label + "&useCache=false")
            .then(data2 => {
                //if no errors
                this.setState({
                    results: data2.data,
                });
            }).finally(() => {
                this.setState({
                    commandprocessing: false,
                });
            }
            );

    }

    private onDelete = (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number) => {

        console.log("FormEdit onDelete");
        var pages = this.state.form.setup.pages

        if (pages.length > 1 && sectionIndex == -1) {
            //this is a page
            if (window.confirm('You are sure you want to delete this page permanently?')) {
                pages = pages.filter((x: Page, pindex: number) => x != null && pindex != pageIndex);
                this.setState({
                    currentPage: 0
                });
            }
        }
        else {
            pages = pages.map((page: Page, pIndex: number) => {
                if (pIndex == pageIndex) {

                    if (lineIndex == -1) {
                        //this is a section
                        if (window.confirm('You are sure you want to delete this section permanently?')) {
                            page.sections = page.sections.filter((x: Section, sindex: number) => x != null && sindex != sectionIndex);
                        }
                    }
                    else {
                        //this is a control
                        page.sections = page.sections.map((section: Section, sIndex: number) => {

                            if (sIndex == sectionIndex) {
                                section.lines = section.lines.map((line: Line, lIndex: number) => {
                                    if (lIndex == lineIndex) {
                                        line.questions = line.questions.filter((question: Question, qIndex: number) => qIndex != controlIndex);
                                    }
                                    return line;
                                })
                            }
                            return section;
                        });
                    }
                }
                return page;

            });
        }
        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
        });

    }

    private onClose = (id: string, pageIndex: number, sectionIndex: number, lineIndex: number, questionIndex: number, q: Question) => {

        console.log("FormEdit onClose");
        var pages = this.state.form.setup.pages

        if (id == "page") {
            pages = pages.map((page: Page, pIndex: number) => {
                if (pIndex == pageIndex) {
                    page.label = q.label;
                    page.description = q.description;
                }
                return page;
            })
        }

        else if (id == "section") {
            pages = pages.map((page: Page, pIndex: number) => {
                if (pIndex == pageIndex) {
                    page.sections = page.sections.map((section: Section, sIndex: number) => {
                        if (sIndex == sectionIndex) {
                            section.label = q.label;
                            section.description = q.description;
                        }
                        return section;
                    })
                }
                return page;
            })
        }
        else {

            pages = pages.map((page: Page, pIndex: number) => {
                if (pIndex == pageIndex) {

                    page.sections = page.sections.map((section: Section, sIndex: number) => {

                        if (sIndex == sectionIndex) {
                            section.lines = section.lines.map((line: Line, lIndex: number) => {
                                if (lIndex == lineIndex) {
                                    line.questions = line.questions.map((question: Question, qIndex: number) => {
                                        if (qIndex == questionIndex) {
                                            question = { ...q };
                                        }
                                        console.log("question", question);
                                        return question;
                                    })
                                }
                                return line;
                            })
                        }
                        return section;
                    })
                }
                return page;

            });
        }

        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
        });
    }

    private onChange = (event: any) => {

        console.log("FormEdit onChange", event, event.target.id);
        var pages = this.state.form.setup.pages


        if (event.target.id.indexOf("label_question_") == 0) {
            pages = pages.map((page: Page, pageIndex: number) => {
                if (pageIndex == this.state.currentPage) {

                    var split: number[] = event.target.id.replace("label_question_", "").split('_');

                    if (split != null && split.length == 3) {
                        var sIndex = split[0];
                        var lIndex = split[1];
                        var qIndex = split[2];
                        console.log("label_question_", sIndex, lIndex, qIndex, event.target.value)
                        page.sections = page.sections.map((section: Section, sectionIndex: number) => {

                            if (sectionIndex == sIndex) {
                                section.lines = section.lines.map((line: Line, lineIndex: number) => {
                                    if (lineIndex == lIndex) {
                                        line.questions = line.questions.map((question: Question, questionIndex: number) => {
                                            if (questionIndex == qIndex) {
                                                question.label = event.target.value;
                                            }
                                            console.log("question", question);
                                            return question;
                                        })
                                    }
                                    console.log("line", line);
                                    return line;
                                })
                            }
                            console.log("section", section);
                            return section;
                        })
                    }
                }
                console.log("page", page);
                return page;
            })
        }

        if (event.target.id.indexOf("description_question_") == 0) {
            pages = pages.map((page: Page, pageIndex: number) => {
                if (pageIndex == this.state.currentPage) {

                    var split: number[] = event.target.id.replace("description_question_", "").split('_');

                    if (split != null && split.length == 3) {
                        var sIndex = split[0];
                        var lIndex = split[1];
                        var qIndex = split[2];
                        console.log("description_question_", sIndex, lIndex, qIndex, event.target.value)
                        page.sections = page.sections.map((section: Section, sectionIndex: number) => {
                            if (sectionIndex == sIndex) {
                                section.lines = section.lines.map((line: Line, lineIndex: number) => {
                                    if (lineIndex == lIndex) {
                                        line.questions = line.questions.map((question: Question, questionIndex: number) => {
                                            if (questionIndex == qIndex) {
                                                question.description = event.target.value;
                                            }
                                            return question;
                                        })
                                    }
                                    return line;
                                })
                            }
                            return section;
                        })
                    }
                }
                return page;
            })
        }


        if (event.target.id.indexOf("description_") == 0) {
            pages = pages.map((page: Page, pageIndex: number) => {
                if (pageIndex == this.state.currentPage) {

                    page.sections = page.sections.map((section: Section, sectionIndex: number) => {
                        if (sectionIndex == 0) {
                            section.lines = section.lines.map((line: Line, lineIndex: number) => {
                                if (lineIndex == 1) {
                                    line.questions = line.questions.map((question: Question, qIndex: number) => {
                                        if (qIndex == 0) {
                                            question.description = event.target.value;
                                        }
                                        return question;
                                    })
                                }
                                return line;
                            })
                        }
                        return section;
                    })
                }
                return page;
            })
        }


        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
        });

    }

    private onDragStart = (event: any) => {
        //event.preventDefault();

        console.log("FormEdit dragStart", event, event.target.id);
        //event.dataTransfer.setData("text/plain", event.target.id);

        this.setState({
            moveid: event.target.id,
        });


    }

    private RemoveDashed() {
        const allElements = document.querySelectorAll('.dashed');

        allElements.forEach((element) => {
            element.classList.remove('dashed');
        });
    }

    private onDragEnd = (event: any) => {
        //event.preventDefault();

        console.log("FormEdit onDragEnd", event, event.target.id);
        //event.dataTransfer.setData("text/plain", event.target.id);

        this.RemoveDashed();


    }


    private onDrop = (sindex: number, lindex: number, position: number, event: any) => {
        event.preventDefault();

        console.log("FormEdit drop main", event, event.target.id, this.state.moveid, sindex, lindex);

        //what to drop
        var field: Question | null = null;
        if (this.state.moveid != null && this.state.moveid.length > 0) {

            var pages = this.state.form.setup.pages;

            if (this.state.moveid.indexOf("button") == 0 || this.state.moveid.indexOf("result") == 0) {
                //this is base button
                if (this.state.moveid.indexOf("button") == 0) {
                    var index2 = Number(this.state.moveid.replace("button", ""));
                    field = this.state.buttons[index2];
                }
                if (this.state.moveid.indexOf("result") == 0) {
                    var index = Number(this.state.moveid.replace("result", ""));
                    field = this.state.results[index];
                }

                console.log("field", field);

                if (field != null) {


                    var newQuestion: Question = { ...field };

                    if (pages[this.state.currentPage].sections[sindex].lines == null && (pages[this.state.currentPage].sections[sindex].lines.length == 0 || lindex > pages[this.state.currentPage].sections[sindex].lines.length - 1)) {
                        var newLine2: Line = {
                            questions: []
                        }
                        pages[this.state.currentPage].sections[sindex].lines.push(newLine2);
                    }

                    //pages[this.state.currentPage].sections[sindex].lines[lindex].questions.push(newQuestion);
                    pages[this.state.currentPage].sections[sindex].lines[lindex].questions.splice(position, 0, newQuestion);

                    this.setState({
                        form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
                    });
                }

            }
            else if (this.state.moveid.indexOf("question") == 0) {

                console.log("question", this.state.moveid);
                //this is a question
                var q: string = this.state.moveid.replace("question_", "");
                var qIndexes: string[] = q.split('_');;

                console.log("question", qIndexes, qIndexes.length);

                if (qIndexes != null && qIndexes.length == 4) {
                    var indexPage: number = Number(qIndexes[0]);
                    var indexSection: number = Number(qIndexes[1]);
                    var indexLine: number = Number(qIndexes[2]);
                    var indexQuestion: number = Number(qIndexes[3]);

                    console.log("question", indexPage, indexSection, indexLine, indexQuestion);

                    field = pages[indexPage].sections[indexSection].lines[indexLine].questions[indexQuestion];

                    console.log("field", field);

                    if (field != null) {


                        var newQuestion2: Question = { ...field };

                        if (pages[this.state.currentPage].sections[sindex].lines == null && (pages[this.state.currentPage].sections[sindex].lines.length == 0 || lindex > pages[this.state.currentPage].sections[sindex].lines.length - 1)) {
                            var newLine: Line = {
                                questions: []
                            }
                            pages[this.state.currentPage].sections[sindex].lines.push(newLine);
                        }

                        //pages[this.state.currentPage].sections[sindex].lines[lindex].questions.push(newQuestion);
                        pages[this.state.currentPage].sections[sindex].lines[lindex].questions.splice(position, 0, newQuestion2);

                        //remove the old field
                        pages[indexPage].sections[indexSection].lines[indexLine].questions.splice(indexQuestion, 1);


                        this.setState({
                            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
                        });
                    }
                }
            }

        }
        this.RemoveDashed();
    }

    private Redirect = () => {

        this.props.navigate("/admin");
    }

    private Save = (callback: () => void) => {
        //event.preventDefault();

        console.log("FormEdit Save");
        //event.dataTransfer.setData("text/plain", event.target.id);

        //alert(dinfo(this.state.form.json));

        axios.post<Form>(BASEAPI + `SaveForm`, this.state.form)
            .then(data2 => {
                //if no errors

                callback();

                /*this.setState({
                    results: data2.data,
                });
                */
            }).finally(() => {
                /*
                this.setState({
                    commandprocessing: false,
                });
                */
            }
            );
    }


    private Publish = () => {
        //event.preventDefault();

        console.log("FormEdit Publish");
        //event.dataTransfer.setData("text/plain", event.target.id);

        //alert(dinfo(this.state.form.json));


        console.log("FormEdit Publish");
        axios.get<string>(BASEAPI + `Publish?Identifier=${this.state.form.identifier}`)
            .then(data2 => {
                //if no errors

                //this.props.navigate("/admin");

                console.log("data2", data2, data2.data)
                this.setState({
                    publishURL: data2.data
                });
                alert("Form was Published");
                /*this.setState({
                    results: data2.data,
                });
                */
            }).finally(() => {
                /*
                this.setState({
                    commandprocessing: false,
                });
                */
            }
            );
    }


    //AddPage: MouseEventHandler<HTMLButtonElement> | undefined;
    private AddPage = (event: any) => {

        console.log("FormEdit onDelete");
        var pages = this.state.form.setup.pages;


        var newSection: Section = {
            label: "Section 1",
            description: "section description",
            lines: []
        };

        var newPage: Page = {

            label: "Page " + pages.length + 1,
            description: "page description",
            sections: [newSection]
        };

        pages.push(newPage);

        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } },
            currentPage: pages.length - 1
        });

    }

    private onAddSection = (pageindex: number) => {

        console.log("FormEdit onDelete");
        var pages = this.state.form.setup.pages;


        var newSection: Section = {
            label: "Section " + (pages[this.state.currentPage].sections.length + 1),
            description: "section description",
            lines: []
        };

        pages[this.state.currentPage].sections.push(newSection);

        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } },
        });
    }

    private ChangePage = (index: number) => {
        this.setState({
            currentPage: index
        });

    }

    public render() {
        return (
            <>
                <div className="container ml-5">
                    <p className="text-2xl font-bold mt-5 uppercase">
                        {this.state.form.label}
                    </p>

                    {dinfo(this.state.moveid)}
                    <div className="flex mt-[1rem]">

                        <div className="w-5/6 ">
                            <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border ">

                                <div key="50" className="w-full border-b-[1px] border-b-[#C0C0C0] p-8 py-4">
                                    {this.state.form.setup.pages.map((p: Page, pageIndex: number) =>
                                        <button key={"page" + pageIndex} id={"page" + pageIndex}
                                            onClick={() => { this.ChangePage(pageIndex) }}
                                            className="inline bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block m-1 p-2 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]" >{p.label}</button>
                                    )}

                                    <button key={"pagenew"} id={"pagenew"}
                                        onClick={this.AddPage}
                                        className="inline bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block m-1 p-2 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]" >Add Page</button>

                                </div>

                                <div key="52" className="w-full">
                                    <PageEditControl page={this.state.form.setup.pages[this.state.currentPage]} index={this.state.currentPage} moveid={this.state.moveid}
                                        onChange={this.onChange}
                                        onDrop={this.onDrop}
                                        onClose={this.onClose}
                                        onDragStart={this.onDragStart}
                                        onDragEnd={this.onDragEnd}
                                        onDelete={this.onDelete}
                                        allowEdit={true}
                                        onAddSection={this.onAddSection} />
                                </div>

                                <div key="51" className="w-full p-8 py-4 text-center">
                                    <button className="inline bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block m-1 p-2 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]"
                                        onClick={() => { this.Save(this.Publish) }}>Save and Publish</button>
                                    <button className="inline bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block m-1 p-2 whitespace-nowrap text-ellipsis overflow-hidden w-[150px]"
                                        onClick={() => { this.Save(this.Redirect) }}>Save and Close</button>
                                </div>

                                {this.state.publishURL != null && this.state.publishURL.length > 0 &&
                                    <div className="text-center">
                                        Form URL: <a href={this.state.publishURL} className="underline" target="_new">{this.state.publishURL}</a>
                                    </div>

                                }
                            </div>
                        </div>

                        <div className="w-1/6 px-3">

                            <div className="text-center bg-[#ECE8FB] p-2.5 m-2 font-medium rounded-md  hover:bg-blue-800 hover:text-white group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#301AB6" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" className="inline w-6 h-6 mt-[-2px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <div className="inline ml-2">
                                    <Link className="text-[#301AB6] group-hover:text-[#FFFFFF]" to={{ pathname: "/admin/preview/" + this.props.identifier }} title={this.props.t("Preview") ?? ""} target="_new" >
                                        {this.props.t("Preview")}
                                    </Link>
                                </div>
                            </div>

                            <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border p-2 ">

                                {this.state.results != null && this.state.results.length > 0 &&
                                    <>
                                        <div className=''>
                                            <span className="font-bold">Suggested Fields:</span>
                                            {this.state.results.map((r: Question, index: number) =>
                                                <React.Fragment key={"result" + index} >
                                                    {r.label != null && r.label.length > 0 &&
                                                        <button key={"result" + index} id={"result" + index}
                                                        className="w-full bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block my-2 p-2 whitespace-nowrap text-ellipsis overflow-hidden text-left "
                                                        draggable={true} onDragStart={this.onDragStart}  >{r.label}</button>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <button className="w-full text-center bg-[#ECE8FB] text-[#301AB6] p-2.5 my-2 font-medium rounded-md  hover:bg-blue-800 hover:text-white " onClick={() => this.onCallGPT()} >
                                             Get New Fields
                                            {this.state.commandprocessing &&
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 text-[#d52162]  animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                </svg>
                                            }

                                        </button>
                                    </>
                                }

                                {this.state.buttons != null && this.state.buttons.length > 0 &&
                                    <div className=' mt-5'>
                                        <span className="font-bold">Drag & Drop Fields:</span>
                                        {this.state.buttons.map((r: Question, index: number) =>
                                            <button key={"button" + index} id={"button" + index}
                                            className="w-full bg-[#FFFFFF] text-[#000000] border-[1px] border-[#CCCCCC] rounded-md block my-2 p-2 whitespace-nowrap text-ellipsis overflow-hidden text-left "
                                                draggable={true} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>{r.label}</button>

                                        )}
                                    </div>
                                }
                            </div>


                            <div className="text-center bg-[#ECE8FB] p-2.5 my-2  font-medium rounded-sm  hover:bg-blue-800 hover:text-white group">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                </svg>
                                <div className="inline ml-2">
                                    <Link className="text-[#301AB6] group-hover:text-[#FFFFFF]" to={{ pathname: "/admin/integrations/" + this.props.identifier }} title={this.props.t("Integrations") ?? "Integrations"}  target="_new" >
                                    {this.props.t("Integrations") || "Integrations"}
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/*dinfo(this.state)*/}
                {/*dinfo(this.state.form.setup.pages[this.state.currentPage])*/}
                {dinfo(this.state.form.setup.pages[this.state.currentPage])}
            </>
        );

    }
}

export default withTranslation()(FormEdit)