import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Answer, BASEAPI, dinfo, Form, IResponse, Line, Page, Question, Section, UserId } from "../objects";
import axios from "axios";
//import PageViewControl from "./PageViewControl";
import PageEditControl from "./PageEditControl";


interface PreviewProps extends WithTranslation {
    //callback: (() => void);
    identifier: string;
    isAdmin: boolean;
}
interface PreviewState {
    //data: ResponsesList,

    form: Form;
    currentPage: number;
    needValidation: boolean;
    isSubmitted: boolean;

}

const defaultForm: Form = {
    id: 0,
    identifier: "",
    isVersion: false,
    label: "",
    modifiedDate: "",
    startDate: "",
    endDate: "",
    setup: {
        label: "",
        description: "",
        pages: [],
    }
}

class Preview extends React.Component<PreviewProps, PreviewState> {
    /*
    constructor(props: PreviewProps) {
        super(props);
    }
    */

    public state: PreviewState = {
        form: defaultForm,
        currentPage: 0,
        needValidation: false,
        isSubmitted: false,
    };

    public componentDidMount() {

        var call = 'GetForm';
        if (this.props.isAdmin) {
            call = 'GetFormPreview';
        }


        axios.get<Form>(BASEAPI + `${call}?Identifier=${this.props.identifier}`)
            .then(data => {
                //if no errors
                this.setState({
                    form: data.data,
                });
            });

    }

    private onChange = (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, answer: Answer) => {

        console.log("Preview onChange", answer, pageIndex, sectionIndex, lineIndex, controlIndex);

        var pages = this.state.form.setup.pages

        pages = pages.map((page: Page, pindex: number) => {

            if (pindex === pageIndex) {
                page.sections = page.sections.map((section: Section, sindex: number) => {

                    if (sindex === sectionIndex) {
                        section.lines = section.lines.map((line: Line, lindex: number) => {

                            if (lindex === lineIndex) {
                                line.questions = line.questions.map((question: Question, qindex: number) => {

                                    if (qindex === controlIndex) {
                                        question.answer = answer;
                                    }
                                    console.log("Preview onChange q");
                                    return question;
                                })
                            }
                            return line;
                        })

                    }
                    return section;

                })

            }
            return page;
        })


        console.log("Preview onChange page", pages[0].sections[0].lines[0].questions[0]);

        this.setState({
            form: { ...this.state.form, setup: { ...this.state.form.setup, pages: pages } }
        });

    }

    private Submit = () => {

        //event.preventDefault();
        //event.stopPropagation();

        this.setState({
            needValidation: true,
        });

        console.log("Submit");

        var f: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;
        if (f != null && f.checkValidity()) {


            var answers: Answer[] = [];

            this.state.form.setup.pages.map((page: Page, index: number) => {
                page.sections = page.sections.map((section: Section, index: number) => {
                    section.lines = section.lines.map((line: Line, index: number) => {
                        line.questions = line.questions.map((question: Question, index: number) => {
                            answers.push(question.answer);
                            return question;
                        })
                        return line;
                    })
                    return section;
                })
                return page;
            })

            var response: IResponse = {
                id: 0,
                //identifier: UserId,
                formId: this.state.form.id,
                formIdentifier: this.state.form.identifier,
                ip: "",
                modifiedDate: "",
                answers: answers,
            }

            console.log("UserId", UserId);

            axios.post<boolean>(BASEAPI + `AddResponse`, response)
                .then(data => {
                    //if no errors
                    /*this.setState({
                        data: data.data,
                    });
                    */
                    //alert('random data submited');

                    if (data.data === false) {
                        alert('An eror occured!!!');
                    }
                    else {
                        this.setState({
                            currentPage: 0,
                            needValidation: false,
                            isSubmitted: true,
                        })

                        //alert('OK');
                    }
                });

        }
        else {
            this.ShowValidations(f);
            //alert('Invalid Form!');
        }
    }


    private Back = (pageIndex: number) => {
        this.setState({
            currentPage: pageIndex,
            needValidation: false,
        })
    }

    private Next = (pageIndex: number) => {

        this.setState({
            needValidation: true,
        })

        var f: HTMLFormElement = document.getElementById("testForm") as HTMLFormElement;
        if (f != null && f.checkValidity()) {

            this.setState({
                currentPage: pageIndex,
                needValidation: false,
            })
        }
        else {
            this.ShowValidations(f);
        }
    }


    private ShowValidations(f: HTMLFormElement) {
        var M = "";
        Array.from(f.elements).forEach((input) => {
            //console.log(input);
            var elem = input as HTMLInputElement;
            var m = "";
            if (!elem.checkValidity()) {
                m = elem.validationMessage;
                M += " " + elem.validationMessage;
            }

            var field: HTMLFormElement = document.getElementById(elem.id + "-error") as HTMLFormElement;
            if (field != null) {
                field.textContent = m;
            }
        });
        console.log(M);
    }

    public render() {
        return (
            <>
                {!this.state.isSubmitted && this.renderForm()}
                {this.state.isSubmitted && this.renderConfirmation()}
            </>
        );

    }

    public renderConfirmation() {
        return (
            <div className="container ml-5">
                <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border p-8 m-10">

                    <div className="text-center">
                        Form Submitted. Thank You!
                    </div>
                </div>
            </div>
        );

    }

    public renderForm() {


        return (
            <>

                <div className="container ml-5">
                    <p className="text-2xl font-bold my-5 ml-10">
                        {this.state.form.label}
                    </p>


                    <div className="bg-[#FFFFFF] rounded-md drop-shadow-xl border p-8 m-10">


                        <div className=" mt-[1rem]">
                            <div className="w-full pr-2">

                                {this.state.form != null && this.state.form.setup != null &&
                                    <>

                                        {this.state.form.setup.pages != null && this.state.form.setup.pages.length > 0 &&
                                            <div className='bg-white py-5 mt-5 shadow-sm'>
                                                {/*this.state.form.setup.pages.map((page: Page, index: number) =>
                                            )*/}
                                                <form id="testForm" noValidate className={this.state.needValidation ? "needValidation" : ""}>
                                                    <PageEditControl key={"ppc"}
                                                        index={this.state.currentPage}
                                                        page={this.state.form.setup.pages[this.state.currentPage]}
                                                        onChange={this.onChange} moveid={""}
                                                        onDrop={function (sindex: number, lindex: number, position: number, event: any): void { }}
                                                        onClose={function (id: string, pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number, value: Question): void { }}
                                                        onDelete={function (pageIndex: number, sectionIndex: number, lineIndex: number, controlIndex: number): void { }}
                                                        onAddSection={function (event: any): void { }}
                                                        onDragStart={function (event: any): void { }}
                                                        onDragEnd={function (event: any): void { }}
                                                        allowEdit={false}

                                                    />
                                                </form>

                                                <div className="text-center">
                                                    {this.state.currentPage > 0 &&
                                                        <button className="bg-[#39afd1] rounded-md text-[#FFFFFF] py-2 px-5 mx-10" onClick={() => this.Back(this.state.currentPage - 1)} >Back</button>
                                                    }
                                                    {this.state.form.setup.pages.length > this.state.currentPage + 1 &&
                                                        <button className="bg-[#39afd1] rounded-md text-[#FFFFFF] py-2 px-5 mx-10" onClick={() => this.Next(this.state.currentPage + 1)} >Next</button>
                                                    }

                                                    {this.state.form.setup.pages.length === this.state.currentPage + 1 &&
                                                        <button className="bg-[#39afd1] rounded-md text-[#FFFFFF] py-2 px-5 mx-10" onClick={() => this.Submit()} >Submit</button>
                                                    }

                                                    {/*<button className="bg-[#FF0000] text-[#FFFFFF] p-3 mx-10" onClick={() => this.Submit()} >B Submit</button>*/}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">

                </div>
                {dinfo(this.state)}
            </>
        );

    }
}

export default withTranslation()(Preview)